<template>
  <Page
    :title="item.date ? `${item.title}, ${formatDate(item.date)}` : item.title"
    color="error"
  >
    <template slot="extension">
      <v-tabs background-color="error darken-1" hide-slider>
        <v-toolbar color="error darken-1" dense>
          <v-btn text exact :to="{ name: 'Galleries' }"
            ><v-icon left>mdi-arrow-left</v-icon>zurück</v-btn
          >
        </v-toolbar>
      </v-tabs>
    </template>

    <v-row>
      <v-col
        v-for="(image, index) in item.images"
        :key="index"
        xl="2"
        lg="3"
        md="4"
        sm="6"
        cols="6"
        align-top
      >
        <GalleryThumbLink :gallery="item.name" :image="image" />
      </v-col>
    </v-row>
    <router-view></router-view>

    <v-footer class="mt-10 pt-10 pb-10" color="white">
      <v-col class="text-center" cols="12"
        ><strong
          >{{ item.title
          }}<template v-if="item.date"
            >, {{ formatDate(item.date) }}</template
          ></strong
        ><br />
        <small
          v-if="item.description"
          class="text-center"
          cols="12"
          v-html="item.description"
        >
        </small>
      </v-col>
    </v-footer>
  </Page>
</template>

<script>
import GalleryThumbLink from './components/GalleryThumbLink.vue';
import { formatDate } from 'common/utils/date';
export default {
  components: { GalleryThumbLink },
  props: ['gallery'],
  data() {
    return {
      item: { title: 'Galerie', images: [] },
    };
  },
  methods: {
    formatDate,
  },
  async created() {
    this.item = await this.apiList({
      resource: 'gallery/gallery',
      query: `gallery=${this.gallery}`,
    });
  },
};
</script>
